import React from 'react';
import { Instagram, LinkedIn } from '@material-ui/icons';
import { XOutlined } from '@ant-design/icons';
// import XIcon from '@mui/icons-material/X';
import googlePlay from '../../../assets/image/GooglePlay-logo.svg';
import appleStore from '../../../assets/image/Apple-logo.svg';

const DownloadApp = ({ styles }) => {
  return (
    <div className="items-right-bottom">
      <div className={styles}>
        <h4>Download our App</h4>
        <p>
          Download our mobile application for better experience and to receive
          custom notifications in real time.
        </p>
        <div className="app-store-btn-2">
          {/* eslint-disable-next-line */}
          <a href="https://apps.apple.com/app/wayabank/id1658552439">
            <img src={appleStore} alt="" className="ios-store-2" />
          </a>
          {/* eslint-disable-next-line */}
          <a href="https://play.google.com/store/apps/details?id=ng.wayabank">
            <img src={googlePlay} alt="" className="google-store-2" />
          </a>
        </div>
        <div
          className="social-media1"
          style={{ paddingBottom: '20px', paddingTop: '20px' }}
        >
          {/* <h4>Follow us on</h4> */}
          <a
            href="https://www.linkedin.com/feed/update/urn:li:activity:7305498508101361664"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedIn />
          </a>
          <a
            href="https://www.instagram.com/p/DHF5Vkfou_c/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
            target="_blank"
            rel="noreferrer"
            className="mx-4"
          >
            <Instagram />
          </a>
          <a href="https://x.com/wayabank_ng" target="_blank" rel="noreferrer">
            <XOutlined />
          </a>
          {/* <XIcon /> */}
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
