import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import 'react-phone-number-input/style.css';
import validator from 'validator';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import '../FundWallet/index.scss';
import PinDetails from '../Confirm/PinDetails';
import { customBaseUrl, httpGet, httpPost } from '../../../../action/http';
import Button from '../../Button';
// import { BillerContext } from '../../../../store/context/BillerContext';
import { WalletContext } from '../../../../store/context/WalletContext';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import moneyFomatter from '../../../../utils/moneyFormatter';
import { hideLoader, showLoader } from '../../../../utils/loader';

const EPin = (props) => {
  const { showModal, hideModal, id, walletdata, setWalletData } = props;
  const [loading, setLoading] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [pin, setPin] = useState('');
  const [network, setNetwork] = useState('');
  const [months, setMonths] = useState('');
  const [balance, setBalance] = useState();
  // const [postData, setPostData] = useState({});
  // const [inputFields, setInputFields] = useState([]);
  const [biller] = useState([]);
  // const [billerCable, setBillerCable] = useState([]);
  const [accountCable, setAccountCable] = useState('');
  // const [bilAirtime] = useState([]);
  const [epinAmount, setEpinAmount] = useState(null);
  const [dataBundle, setDataBundle] = useState([]);
  const [charges, setCharges] = useState(0);
  const [activeAggregator, setAggregator] = useState('');
  const [verifyLoading, setVerifyLoading] = useState(false);
  // const { epin } = useContext(BillerContext);
  const [epin, setEpin] = useState([]);
  const { setReload, walletList, reload } = useContext(WalletContext);
  const { profile } = useContext(ProfileContext);
  const [product, setProduct] = useState([]);
  const [bundle, setBundle] = useState([]);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [numberOfPins, setNoOfPins] = useState(1);
  // const [isValidationRequired, setIsValidationRequired] = useState(false);
  // const [customerName, setCustomerName] = useState('');

  /* const getAmount = (bundles) => {
    if (bundles.length) {
      // console.log('amount', bundles?.amount);
      setEpinAmount(+bundles?.amount);
    }
  }; */

  /*  const getbillerCategory = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl}/api/v1/category/${biller}`
    );
    if (res?.status) {
      // console.log('billerCategory', res);
      setBillerCable(res.data);
    }
    // console.log('billerNameData', billerCable);
  };  */

  const getProduct = async (bid) => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchProductByBiller?serviceProviderBillerId=${bid}`
    );
    if (res?.status) {
      hideLoader();
      setProduct(res?.data);
    } else {
      hideLoader();
    }
  };
  const getBundle = async (pid) => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchBundleByProduct?serviceProviderProductId=${pid}`
    );
    if (res?.status) {
      hideLoader();
      setBundle(res?.data);
      // setAvailableCokie(res?.data?.data?)
    } else {
      hideLoader();
    }
  };
  // const getAgregator = async () => {
  //   const res = await httpGet(
  //     `${customBaseUrl.billPaymentUrl}/api/v1/config/aggregator`
  //   );
  //   const aggregator = res?.data;
  //   const quick =
  //     aggregator?.filter((e) => e.aggregator === 'QUICKTELLER') || {};
  //   const baxi = aggregator?.filter((e) => e.aggregator === 'BAXI') || {};
  //   const itex = aggregator?.filter((e) => e.aggregator === 'ITEX') || {};
  //   if (quick[0]?.active) {
  //     setAggregator('QUICKTELLER');
  //   } else if (baxi[0]?.active) {
  //     setAggregator('BAXI');
  //   } else if (itex[0]?.active) {
  //     setAggregator('ITEX');
  //   }
  // };

  const getCharges = async () => {
    const res = await httpGet(
      `${
        customBaseUrl.temporalservice
      }/api/v1/wallet/transaction/get-user-transaction-fee/${
        walletdata?.walletAccountNo
      }/${epinAmount * numberOfPins}/${activeAggregator}`
    );
    // console.log('res', res);
    if (res?.status !== 404) {
      setCharges(res);
    }
    // console.log('Charges', charges);
  };
  const handleInitialize = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/bills/test/initiate?serviceType=epin`
    );
    console.log('res', res);
    if (res?.status === true) {
      setEpin(res.data);
    }
  };

  useEffect(() => {
    handleInitialize();
  }, []);
  useEffect(() => {
    if (activeAggregator && walletdata?.walletAccountNo && epinAmount)
      getCharges();
  }, [walletdata?.walletAccountNo, epinAmount]);

  // useEffect(() => {
  //   getAgregator();
  // }, []);

  const handleSubmit = async () => {
    // console.log('bundle', dataBundle);
    setLoading(true);
    if (validator.isEmpty(profile?.phoneNumber)) {
      swal('Oops!', 'Phone number cannot be empty', 'error');
      setLoading(false);
      return;
    }

    /*  const formattedPhone = `${parsePhoneNumber(phone).countryCallingCode}${
      parsePhoneNumber(phone).nationalNumber
    }`;

    if (/\D/.test(formattedPhone)) {
      swal(
        'Oops!',
        'Phone number accepts only numeric characters (Allowed input:0-9)',
        'error'
      );
      setLoading(false);
      return;
    }

    if (formattedPhone.length > 13) {
      swal('Oops!', 'Phone number cannot be be less than 13 numbers', 'error');
      setLoading(false);
      return;
    } */

    if (dataBundle.length < 0) {
      swal('Oops', 'Choose a Product', 'error');
      setLoading(false);
      return;
    }
    showLoader();
    const data = {
      amount: epinAmount * numberOfPins,
      fixAmount: epinAmount,
      numberOfPins,
      phone: profile?.phoneNumber,
      productCode: dataBundle?.serviceProviderProduct?.productCode,
      type: dataBundle?.serviceProviderProduct?.type,
    };
    const res = await httpPost(
      `/api/v1/epin/pay?serviceProviderBillerId=${dataBundle?.serviceProviderProduct?.serviceProviderBiller?.billerId}&serviceProviderBundleId=${dataBundle?.id}&serviceProviderId=${dataBundle?.serviceProviderProduct?.serviceProviderBiller?.serviceProviderId}&userAccountNumber=${accountCable}`,
      data,
      customBaseUrl.billPaymentUrl2,
      pin
    );
    if (res?.status) {
      setLoading(false);
      swal('Done', JSON.stringify(res?.data?.pins), res?.message);
      hideModal(false);
      setReload(!reload);
      setPin('');
      hideLoader();
    } else {
      setLoading(false);
      hideLoader();
      swal('Oops', res?.message, 'error');
      setPin('');
    }
    hideLoader();
  };
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center" toggle={() => hideModal(false)}>
        EPin
      </ModalHeader>
      <ModalBody>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            <select
              // value={network}
              onChange={(e) => {
                // getFieldParams(e.target.value);
                setProduct([]);
                setBundle([]);
                setCustomerDetails([]);
                const {
                  hasProduct,
                  name,
                  // id: pId,
                  // serviceProviderCategory: {
                  //   serviceProvider: { name },
                  // },
                } = JSON.parse(e.target.value);
                setAggregator(name);
                if (hasProduct) {
                  getProduct(name);
                }
                setNetwork(JSON.parse(e.target.value));
              }}
            >
              <option value="" selected data-default hidden>
                Service Provider
              </option>
              {epin.length
                ? epin.map((item) => (
                    <option value={JSON.stringify(item)} key={item.id}>
                      {item.name}
                    </option>
                  ))
                : ''}
            </select>
          </div>

          {product?.length > 0 ? (
            <div className="inputbox-with-one-input">
              <select
                //  value={dataBundle}
                onChange={(e) => {
                  // getFieldParams(e.target.value);
                  //  getAmount(e.target.value);
                  setEpinAmount('');
                  if (JSON.parse(e.target.value)?.hasBundle) {
                    getBundle(JSON.parse(e.target.value)?.id);
                  }
                  //  setAirtimeProd(e.target.value);
                }}
              >
                <option value="" selected data-default hidden>
                  Select Product
                </option>
                {product
                  ? product.map((item) => (
                      <option value={JSON.stringify(item)} key={item.id}>
                        {item.name}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
          ) : (
            ''
          )}
          {bundle?.length > 0 ? (
            <div className="inputbox-with-one-input">
              <select
                //  value={dataBundle}
                onChange={(e) => {
                  // getFieldParams(e.target.value);
                  setDataBundle(JSON.parse(e.target.value));
                  setEpinAmount(JSON.parse(e.target.value)?.amount);
                }}
              >
                <option value="" selected data-default hidden>
                  Select Bundle
                </option>
                {bundle
                  ? bundle.map((item) => (
                      <option value={JSON.stringify(item)} key={item.id}>
                        {item.name} - N{moneyFomatter(item.amount)} -{}
                        {item.validity}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
          ) : (
            ''
          )}
          {bundle.length > 0 ? (
            <>
              <div className="input-group mb-3 col">
                <span className="input-group-text" id="basic-addon3">
                  Fixed Amount
                </span>
                <input
                  disabled
                  type="text"
                  aria-label="Ref"
                  className="form-control"
                  value={epinAmount ? `N${moneyFomatter(epinAmount)}` : ''}
                />
              </div>
              <div className="input-group mb-3 col">
                <span className="input-group-text" id="basic-addon3">
                  Number of Pins
                </span>
                <input
                  type="number"
                  aria-label="Ref"
                  className="form-control"
                  value={numberOfPins}
                  onChange={(e) => setNoOfPins(e.target.value)}
                />
              </div>
              <div className="input-group mb-3 col">
                <span className="input-group-text" id="basic-addon3">
                  Amount
                </span>
                <input
                  disabled
                  type="text"
                  aria-label="Ref"
                  className="form-control"
                  value={
                    epinAmount
                      ? `N${moneyFomatter(epinAmount * numberOfPins)}`
                      : ''
                  }
                />
              </div>
            </>
          ) : (
            ''
          )}
          {customerDetails?.name ? (
            <div className="inputbox-with-one-input">
              <input
                type="text"
                required
                disabled
                value={customerDetails?.name}
                placeholder="custmer name"
              />
            </div>
          ) : (
            ''
          )}
          {network && biller === 'epin' ? (
            <div className="inputbox-with-one-input">
              <input
                type="number"
                value={months}
                placeholder="Number of Months"
                onChange={(e) => {
                  setMonths(e.target.value);
                }}
              />
            </div>
          ) : (
            ''
          )}

          {network ? (
            <div className="inputbox-with-one-input">
              {/* <input
                type="text"
                placeholder="Phone"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              /> */}

              <input
                placeholder="Phone Number"
                disabled
                value={profile?.phoneNumber}
              />
            </div>
          ) : (
            ''
          )}

          <div className="inputbox-with-one-input">
            {/* <input placeholder="Card to Debit" type="text" /> */}
            <select
              name="filter"
              className=""
              value={walletdata.walletAccountId}
              onChange={(e) => {
                const [accountNo, amount] = e.target.value.split(',');
                setWalletData({
                  ...walletdata,
                  walletAccountNo: accountNo,
                });
                setBalance(amount);
                setAccountCable(accountNo);
              }}
            >
              <option value="Select" selected>
                Select Account
              </option>
              {walletList.length
                ? walletList.map(
                    (item) =>
                      item.savingsProductName !== 'COMMISSION' && (
                        <option
                          value={`${item.accountNo},${item?.clr_bal_amt}`}
                          key={item.accountNo}
                        >
                          {/* {`${item?.acct_crncy_code}-${item?.accountNo}`} */}
                          {`${item?.description} - ${item?.accountNo} (${
                            item.acct_crncy_code
                          } ${moneyFomatter(
                            Number(item?.clr_bal_amt).toFixed(2)
                          )})`}
                        </option>
                      )
                  )
                : ''}
            </select>
            <span className="input-detail">
              Your Balance is N{balance || 0}.00
            </span>
          </div>
          <div>
            <span> Transaction Fees N{charges || 0}.00 </span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-primary mx-auto mb-3 mt-2"
          color="primary"
          loading={loading}
          disabled={loading || false}
          onClick={() => {
            setShowPin(true);
          }}
        >
          Next
        </Button>
      </ModalFooter>
      {showPin ? (
        <PinDetails
          showModal={showPin}
          hideModal={setShowPin}
          handleNext={handleSubmit}
          id="success-wrapper"
          title="EPin"
          otp={pin}
          setOtp={setPin}
          setLoading={setVerifyLoading}
          loading={verifyLoading}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

EPin.defaultProps = {
  id: '',
};

EPin.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default EPin;
