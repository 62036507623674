import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import { toJpeg } from 'html-to-image';
// import html2canvas from 'html2canvas';
// import JSPDF from 'jspdf';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
// import { exportComponentAsJPEG } from 'react-component-export-image';
// import moment from 'moment';
import './index.scss';
// import CustomDropdown from '../../Dropdown';
// import logo from '../../../../assets/images/wayaBankLogo1.png';
// import logo from '../../../../assets/newlog.svg';
import { capitalize } from '../../../../utils/helper';
// import { httpGet } from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import envConfig from '../../../../utils/envConfig';

const DisputeReceipt = React.forwardRef((props) => {
  const { showModal, hideModal, id, data, title } = props;
  const componentRef = useRef();
  const [receiptData, setReceiptData] = useState([]);
  const { grantType, clientId, secretId, disputeURL } = envConfig;
  //   const [pinD, setPinsD] = useState([]);
  //   const [copied, setCopied] = useState(false);
  // // console.log('datadata', receiptData);y

  const getDetails = async () => {
    try {
      showLoader();
      const authData = new FormData();
      authData.append('grant_type', grantType);
      authData.append('client_id', clientId);
      authData.append('client_secret', secretId);
      const authRes = await axios.post(
        `${disputeURL}/api/oauth2/token`,
        authData,
        {
          headers: {
            Authorization: '',
          },
        }
      );

      //  http://35.178.153.136:8000/api/merchants/MERCHANT003/disputes?status=RESOLVED&type=POS&page=1&size=10
      const res = await axios.get(
        `${disputeURL}/api/dispute/detail?transaction_id=${data.transaction_id}`,
        {
          headers: {
            Authorization: `${authRes.data.token_type} ${authRes.data.access_token}`,
            secret_id: secretId,
            client_id: clientId,
          },
        }
      );
      if (res.data) {
        // setFiltered(res.data.data);
        setReceiptData(res.data);
      }
      hideLoader();
    } catch (err) {
      console.log(err);
      hideLoader();
    }
  };
  useEffect(() => {
    // setReceiptData(data);
    getDetails();
    // console.log('data', data);
    // if (data['Transaction Category'] === 'WITHDRAW') {
    //   getReceipt();
    // } else if (data['Transaction Category'] === 'UTILITY') {
    //   getUtilityReceipt();
    // } else if (data?.Narration === 'BILLS_PAYMENT') {
    //   getUtilityReceipt();
    // } else {
    //   setReceiptData(data);
    // }
  }, []);

  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader
        className="text-center border-0"
        // toggle={() => hideModal(false)}
      >
        <h5 className="justify-content-center">{'  '}</h5>
        <div className="ml-auto" style={{ marginTop: '12px' }}>
          <div className="dot-3x" style={{ position: 'relative' }}>
            {/* <CustomDropdown
              id={12}
              direction="vertical"
              handleOption={handleOption}
              options={[
                { name: 'Print', type: 'download', id: 1 },
                { name: 'Save', type: 'save', id: 2 },
                // { name: 'Share', type: 'share', id: 2 },
                { name: 'Cancel', type: 'cancel', id: 3 },
              ]}
            /> */}
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="body-wrapper m-5 p-0" id="qr-body">
        <div ref={componentRef}>
          {/* <div className="top text-center my-4">
            <img
              src={logo}
              alt="waya"
              style={{ height: '30px', marginTop: '20px' }}
            />
          </div> */}
          <div className="top text-center my-4">
            <h4>{title}</h4>
          </div>
          <div
            className="middle mx-auto"
            style={{ width: '90%', fontSize: '10px' }}
          >
            <table className="table table-borderless">
              <tbody>
                {receiptData &&
                  Object.keys(receiptData).map((keyName) =>
                    keyName === 'atm_dispute' ? (
                      <>
                        <tr key={keyName}>
                          <td>RRN</td>
                          <td>{receiptData[keyName]?.rrn ?? '--'}</td>
                        </tr>
                        <tr key={keyName}>
                          <td>PAN</td>
                          <td>{receiptData[keyName]?.pan ?? '--'}</td>
                        </tr>
                        <tr key={keyName}>
                          <td>STAN</td>
                          <td>{receiptData[keyName]?.stan ?? '--'}</td>
                        </tr>
                      </>
                    ) : keyName === 'bill_payment_dispute' ? (
                      <>
                        <tr key={keyName}>
                          <td>Bill Category</td>
                          <td>{receiptData[keyName]?.bill_category ?? '--'}</td>
                        </tr>
                        {/* <tr key={keyName}>
                          <td>PAN</td>
                          <td>{receiptData[keyName]?.biller_name ?? '--'}</td>
                        </tr> */}
                        <tr key={keyName}>
                          <td>Biller Name</td>
                          <td>{receiptData[keyName]?.biller_name ?? '--'}</td>
                        </tr>
                      </>
                    ) : (
                      <tr key={keyName}>
                        <td>{capitalize(keyName)}: </td>
                        <td>{receiptData[keyName] ?? '--'}</td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
});

DisputeReceipt.defaultProps = {
  id: '',
};

DisputeReceipt.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default DisputeReceipt;
