import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Tabs } from '../Wayapay/trasactionCard';
import Layout from '../../shared/Layout';
import TransactionTable from '../../shared/Table/transaction';
import CreateDispute from '../Wayagram/Dispute/CreateDispute';
// import { Add } from '@mui/icons-material';
import envConfig from '../../../utils/envConfig';
import { hideLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';
import DisputeReceipt from '../../shared/Modals/Receipt/disputeReceipt';

// import { ProfileContext } from '../../../store/context/ProfileContext';

export default function Dispute() {
  // const { user } = JSON.parse(localStorage.getItem('userDetails'));
  // const user = {};
  const { userDetails } = useContext(ProfileContext);
  //   const { userDetails } = useContext(ProfileContext);
  const [tab, setTab] = useState(1);
  const [filtered, setFiltered] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const DisputesTab = ['My Dispute', 'Dispute Notification'];
  const { grantType, clientId, secretId, disputeURL } = envConfig;
  const [selectedData, setSelectedData] = useState({});
  const [showReceipt, setShowReceipt] = useState(false);

  const handleInit = async () => {
    try {
      // showLoader();
      const authData = new FormData();
      authData.append('grant_type', grantType);
      authData.append('client_id', clientId);
      authData.append('client_secret', secretId);
      const authRes = await axios.post(
        `${disputeURL}/api/oauth2/token`,
        authData,
        {
          headers: {
            Authorization: '',
          },
        }
      );

      //  http://35.178.153.136:8000/api/merchants/MERCHANT003/disputes?status=RESOLVED&type=POS&page=1&size=10
      const res = await axios.get(
        `${disputeURL}/api/user/disputes?user_id=${userDetails.userId}&page=1&size=100`,
        {
          headers: {
            Authorization: `${authRes.data.token_type} ${authRes.data.access_token}`,
            secret_id: secretId,
            client_id: clientId,
          },
        }
      );
      const res2 = await axios.get(
        `${disputeURL}/api/disputes/notifications?page=1&size=10`,
        {
          headers: {
            Authorization: `${authRes.data.token_type} ${authRes.data.access_token}`,
            secret_id: secretId,
            client_id: clientId,
          },
        }
      );
      if (res.data) {
        setFiltered(res.data.data);
      }
      if (res2.data) {
        setNotifications(res2.data);
      }
      hideLoader();
    } catch (err) {
      console.log(err);
      hideLoader();
    }
  };

  useEffect(() => {
    handleInit();
  }, [refresh, userDetails]);
  // useEffect(() => {
  //   handleInit();
  // }, []);
  return (
    <Layout>
      <div className="mx-2 my-2" style={{ textAlign: 'end' }}>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setShowCreate(true)}
        >
          {/* <Add /> */}
          Raise new Dispute
        </button>
        <Tabs data={DisputesTab} tab={tab} setTab={setTab} />
      </div>
      <div>
        {tab === 1 ? (
          <TransactionTable
            // hideModal={hideModal}
            options={{
              exportButton: false,
              search: false,
              sorting: false,
              paging: false,
              headerStyle: {
                backgroundColor: '#FF6700',
                color: '#ffffff',
                whiteSpace: 'nowrap',
                zIndex: 0,
              },
            }}
            columns={[
              { title: 'Dispute id', field: 'id' },
              // { title: 'Merchant Id', field: 'merchant_id' },
              { title: 'Dispute Type', field: 'category' },
              { title: 'Transaction ID', field: 'transaction_id' },
              { title: 'Amount', field: 'amount' },
              { title: 'Transaction Date', field: 'trans_date' },
              { title: 'Logged Date', field: 'log_date' },
              { title: 'Status', field: 'status' },
              // { title: 'Bill Category', field: 'biller_category' },
              // { title: 'Biller Name', field: 'biller' },
              // // { title: 'Resolution Reference', field: 'reference' },
              // { title: 'Resolution Date', field: 'resolution_date' },
              // // { title: 'Status', field: 'status' },
              { title: 'Action', field: 'action' },
            ]}
            data={
              filtered?.length > 0 &&
              filtered?.map((el) => {
                return {
                  id: el.id,
                  category: el.type,
                  transaction_id: el?.transaction_id,
                  amount: el?.amount,
                  trans_date: moment(el?.trans_date).format('DD/MM/YY'),
                  biller_category:
                    el?.bill_payment_dispute?.bill_category ?? 'N/A',
                  biller: el?.bill_payment_dispute?.biller_name ?? 'N/A',
                  senderName: el.senderName,
                  merchant_id: el.merchant_id,
                  //  acctNum: el.acctNum,
                  log_date: moment(el.log_date).format('DD/MM/YY'),
                  status: el.status,
                  resolution_date: el.resolution_date
                    ? moment(el.resolution_date).format('DD/MM/YY')
                    : 'N/A',
                  createdAt: el.createdAt,
                  action: (
                    <div
                      onClick={() => {
                        setSelectedData(el);
                        setShowReceipt(true);
                      }}
                      className="d-flex align-content-center"
                    >
                      <span
                        style={{
                          background: '#FFFFFF',
                          border: '1px solid #D0CFCF',
                          boxSizing: 'border-box',
                          borderRadius: '3px',
                          padding: '5px 5px',
                        }}
                        onClick={() => {}}
                      >
                        View More
                      </span>
                    </div>
                  ),
                };
              })
            }
          />
        ) : (
          <TransactionTable
            // hideModal={hideModal}
            options={{
              exportButton: false,
              search: false,
              sorting: false,
              paging: false,
              headerStyle: {
                backgroundColor: '#FF6700',
                color: '#ffffff',
                whiteSpace: 'nowrap',
                zIndex: 0,
              },
            }}
            columns={[
              { title: 'ID', field: 'id' },
              { title: 'Transaction Id', field: 'transaction_id' },
              { title: 'Merchant Id', field: 'merchant_id' },
              { title: 'Dispute Category', field: 'category' },
              { title: 'Logged Date', field: 'log_date' },
              { title: 'Transaction Date', field: 'trans_date' },
              { title: 'Status', field: 'status' },
              { title: 'Resolution Date', field: 'resolution_date' },
              // { title: 'Status', field: 'status' },
              { title: 'Remark', field: 'status' },
              { title: 'Action', field: 'action' },
            ]}
            data={
              notifications?.length > 0 &&
              notifications?.map((el) => {
                return {
                  id: el.id,
                  category: el.type,
                  senderName: el.senderName,
                  transaction_id: el.transaction_id,
                  merchant_id: el.merchant_id,
                  //  acctNum: el.acctNum,
                  log_date: moment(el.log_date).format('DD/MM/YYYY'),
                  trans_date: moment(el.trans_date).format('DD/MM/YYYY'),
                  status: el.status,
                  resolution_date: el.resolution_date
                    ? moment(el.resolution_date).format('DD/MM/YYYY')
                    : 'N/A',
                  createdAt: el.createdAt,
                  action: (
                    <div
                      onClick={() => {
                        setSelectedData(el);
                        setShowReceipt(true);
                      }}
                      className="d-flex align-content-center"
                    >
                      <span
                        style={{
                          background: '#FFFFFF',
                          border: '1px solid #D0CFCF',
                          boxSizing: 'border-box',
                          borderRadius: '3px',
                          padding: '5px 5px',
                        }}
                        onClick={() => {}}
                      >
                        View More
                      </span>
                    </div>
                  ),
                };
              })
            }
          />
        )}
      </div>
      {showCreate ? (
        <CreateDispute
          setRefresh={setRefresh}
          refresh={refresh}
          showModal={showCreate}
          hideModal={setShowCreate}
        />
      ) : (
        ''
      )}
      {showReceipt ? (
        <DisputeReceipt
          showModal={showReceipt}
          hideModal={setShowReceipt}
          data={selectedData}
          id="receipt-wrapper"
          title="Dispute Details"
          tab={tab === 3}
          // maskingString={maskingString}
        />
      ) : (
        ''
      )}
    </Layout>
  );
}
