import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import 'react-phone-number-input/style.css';
import validator from 'validator';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Button from '../../Button';
import '../FundWallet/index.scss';
import PinDetails from '../Confirm/PinDetails';
import { customBaseUrl, httpGet, httpPost } from '../../../../action/http';
// import { BillerContext } from '../../../../store/context/BillerContext';
import { WalletContext } from '../../../../store/context/WalletContext';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import moneyFomatter from '../../../../utils/moneyFormatter';
import { hideLoader, showLoader } from '../../../../utils/loader';

const UtilityBills = (props) => {
  const { showModal, hideModal, id, walletdata, setWalletData } = props;
  const [loading, setLoading] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [network, setNetwork] = useState('');
  const [pin, setPin] = useState('');
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [accountAirtime, setAccountAirtime] = useState('');
  const [balance, setBalance] = useState();
  const [utilityAmount, setUtilityAmount] = useState();
  // const { electricity } = useContext(BillerContext);
  const [electricity, setElectricity] = useState([]);
  const { setReload, reload, walletList } = useContext(WalletContext);
  const { profile } = useContext(ProfileContext);
  //  const [paymentCode] = useState('');
  const [charges, setCharges] = useState(0);
  const [activeAggregator, setAggregator] = useState('');
  const [product, setProduct] = useState([]);
  const [providerData, setProviderData] = useState({});
  const [customerDetails, setCustomerDetails] = useState([]);
  const [meterNo, setMeterNo] = useState('');
  // const [type, setType] = useState('prepaid');
  const [setUtilityBundle] = useState('');

  // const getAgregator = async () => {
  //   const res = await httpGet(
  //     `${customBaseUrl.billPaymentUrl}/api/v1/config/aggregator`
  //   );
  //   const aggregator = res?.data;
  //   const quick =
  //     aggregator?.filter((e) => e.aggregator === 'QUICKTELLER') || {};
  //   const baxi = aggregator?.filter((e) => e.aggregator === 'BAXI') || {};
  //   const itex = aggregator?.filter((e) => e.aggregator === 'ITEX') || {};
  //   if (quick[0]?.active) {
  //     setAggregator('QUICKTELLER');
  //   } else if (baxi[0]?.active) {
  //     setAggregator('BAXI');
  //   } else if (itex[0]?.active) {
  //     setAggregator('ITEX');
  //   }
  // };

  const getCharges = async () => {
    if (walletdata?.walletAccountNo && utilityAmount && activeAggregator) {
      const res = await httpGet(
        `${customBaseUrl.temporalservice}/api/v1/wallet/transaction/get-user-transaction-fee/${walletdata?.walletAccountNo}/${utilityAmount}/${activeAggregator}`
      );
      // console.log('res', res);
      if (res?.status !== 404) {
        setCharges(res);
      }
      // console.log('Charges', charges);
    }
  };
  const handleInitialize = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/bills/test/initiate?serviceType=electricity`
    );
    console.log('res', res);
    if (res?.status === true) {
      setElectricity(res.data);
    }
  };

  useEffect(() => {
    handleInitialize();
  }, []);

  useEffect(() => {
    if (activeAggregator) getCharges();
  }, [walletdata?.walletAccountNo, utilityAmount]);
  // useEffect(() => {
  //   getAgregator();
  // }, []);

  const handleSubmit = async () => {
    setLoading(true);
    if (validator.isEmpty(profile?.phoneNumber)) {
      swal('Oops!', 'Phone number cannot be empty', 'error');
      setLoading(false);
      return;
    }
    /*  const formattedPhone = `${parsePhoneNumber(phone).countryCallingCode}${
      parsePhoneNumber(phone).nationalNumber
    }`;

    if (/\D/.test(formattedPhone)) {
      swal(
        'Oops!',
        'Phone number accepts only numeric characters (Allowed input:0-9)',
        'error'
      );
      setLoading(false);
      return;
    }

    if (formattedPhone.length > 13) {
      swal('Oops!', 'Phone number cannot be be less than 13 numbers', 'error');
      setLoading(false);
      return;
    } */
    showLoader();
    const phoneNumber = profile?.phoneNumber.slice(3);
    // const data = {
    //   amount: utilityAmount,
    //   phone: `0${phoneNumber}`,
    //   account: meterNo,
    //   type: utilityBundle?.type ?? providerData?.type,
    //   // defaultWallet.id,
    // };
    const data = {
      accountNumber: meterNo,
      amount: utilityAmount,
      phone: `0${phoneNumber}`,
      provider: activeAggregator,
      sourceAccount: {
        account: accountAirtime,
        pin,
      },
      userEmail: profile.email,
    };
    const res = await httpPost(
      `/api/bills/test/electricity/pay`,
      data,
      customBaseUrl.billPaymentUrl2
    );
    if (res?.status) {
      // // console.log(res);
      setLoading(false);
      hideLoader();
      // if (type === 'prepaid') {
      //   swal(
      //     'Done',
      //     `Token:${res.data?.tokenCode} Unit: ${res?.data?.amountOfPower}`,
      //     'success'
      //   );
      // } else {
      //   swal('Done', `${res.message}`, 'success');
      // }
      swal('Done', `${res.message}`, 'success');
      hideModal(false);
      setReload(!reload);
      setPin('');
    } else {
      setLoading(false);
      hideLoader();
      setPin('');
      swal('Oops', res.message, 'error');
    }
    hideLoader();
  };
  const getProduct = async (bid) => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchProductByBiller?serviceProviderBillerId=${bid}`
    );
    if (res?.status) {
      hideLoader();
      setProduct(res?.data);
    } else {
      hideLoader();
    }
  };
  const VerifyCustomer = async (sCard) => {
    const data = {
      accountNumber: sCard,
      provider: activeAggregator,
      // serviceProviderCategoryId: providerData?.categoryId,
      // serviceProviderId: providerData?.providerId,
      // type: utilityBundle?.type ?? providerData?.type,
    };
    showLoader();
    try {
      const res = await httpPost(
        `/api/bills/test/customer/validate`,
        data,
        customBaseUrl.billPaymentUrl2
      );
      if (res?.status) {
        hideLoader();
        setCustomerDetails(res?.data?.data?.user);
      } else {
        setCustomerDetails({ status: false });
        hideLoader();
      }
    } catch (error) {
      hideLoader();
    } finally {
      hideLoader();
    }
  };
  return (
    <Modal isOpen={showModal} toggle={() => hideModal(false)} id={id} centered>
      <ModalHeader className="text-center" toggle={() => hideModal(false)}>
        Pay Utility Bills
      </ModalHeader>
      <ModalBody>
        <form className="mx-auto mt-3">
          <div className="inputbox-with-one-input">
            <select
              // value={network}
              onChange={(e) => {
                const {
                  hasProduct,
                  name,
                  // type: pType,
                  // id: pId,
                  // billerId,
                  // serviceProviderCategory: {
                  //   id: cid,
                  //   serviceProvider: { id: pid, name },
                  // },
                } = JSON.parse(e.target.value);
                // setProviderData({
                //   ...providerData,
                //   categoryId: cid,
                //   providerId: pid,
                //   type: pType,
                //   billerId,
                // });

                setAggregator(name);
                setProviderData(JSON.parse(e.target.value));
                if (hasProduct) {
                  getProduct(name);
                }
                setNetwork(JSON.parse(e.target.value));
              }}
            >
              <option value="Select" selected data-default hidden>
                Choose a Company
              </option>
              {electricity.length
                ? electricity.map((item) => (
                    <option value={JSON.stringify(item)} key={item.id}>
                      {item.name.replaceAll('_', ' ').toUpperCase()}
                    </option>
                  ))
                : ''}
            </select>
          </div>
          {product.length > 0 ? (
            <div className="inputbox-with-one-input">
              <select
                //  value={dataBundle}
                onChange={(e) => {
                  // getFieldParams(e.target.value);
                  //  getAmount(e.target.value);
                  setUtilityBundle(JSON.parse(e.target.value));
                  setUtilityAmount('');
                  if (JSON.parse(e.target.value)?.hasBundle) {
                    // getBundle(JSON.parse(e.target.value)?.id);
                  }
                  //  setAirtimeProd(e.target.value);
                }}
              >
                <option value="" selected data-default hidden>
                  Select Product
                </option>
                {product
                  ? product.map((item) => (
                      <option value={JSON.stringify(item)} key={item.id}>
                        {item.name}
                      </option>
                    ))
                  : ''}
              </select>
            </div>
          ) : (
            ''
          )}
          {/* <div className="inputbox-with-one-input">
            <select onChange={(e) => setType(e.target.value)}>
              <option value="prepaid">Prepaid</option>
              <option value="postpaid">Postpaid</option>
            </select>
          </div> */}
          <div className="inputbox-with-one-input">
            <input
              type="number"
              value={utilityAmount}
              placeholder="Amount"
              onChange={(e) => {
                setUtilityAmount(e.target.value);
              }}
            />
          </div>
          {network && providerData && (
            <>
              <div className="inputbox-with-one-input">
                <input
                  type="number"
                  value={meterNo}
                  placeholder="Meter Number"
                  onChange={(e) => {
                    setMeterNo(e.target.value);
                    setCustomerDetails([]);
                    if (e.target.value.length > 9) {
                      VerifyCustomer(e.target.value);
                    }
                  }}
                />
              </div>
              {customerDetails?.name ? (
                <div className="inputbox-with-one-input">
                  <input
                    type="text"
                    disabled
                    value={customerDetails?.name}
                    placeholder="customer name"
                  />
                </div>
              ) : (
                <div className="inputbox-with-one-input">
                  <input
                    type="text"
                    disabled
                    value={
                      customerDetails.length > 0 && !customerDetails?.status
                        ? 'Invalid meter no'
                        : ''
                    }
                    placeholder="custmer name"
                  />
                </div>
              )}
            </>
          )}
          {/* {network ? (
            <div className="inputbox-with-one-input">
              <input
                placeholder="Phone Number"
                disabled
                value={profile?.phoneNumber}
              />
            </div>
          ) : (
            ''
          )} */}

          {/* {inputFields.length
            ? inputFields.map((item) => (
                <div className="inputbox-with-one-input" key={item.paramName}>
                  {item.subItems.length ? (
                    <select
                      value={postData.paramName}
                      onChange={(e) => {
                        const [name, amount] = e.target.value.split(',');
                        setPostData(
                          item.isAmountFixed
                            ? {
                                ...postData,
                                [item.paramName]: name,
                                total_amount: amount,
                              }
                            : {
                                ...postData,
                                [item.paramName]: e.target.value,
                              }
                        );
                      }}
                    >
                      <option value="Select" selected disabled>
                        {item.paramName}
                      </option>
                      {item.subItems.length
                        ? item.subItems.map((items) => (
                            <option
                              value={
                                !item.isAmountFixed
                                  ? items.name
                                  : `${items.name},${items.amount}`
                              }
                              key={items.name}
                            >
                              {items.name}
                            </option>
                          ))
                        : ''}
                    </select>
                  ) : (
                    <input
                      placeholder={item.paramName}
                      type="text"
                      value={postData[item.paramName]}
                      onChange={(e) => {
                        setPostData({
                          ...postData,
                          [item.paramName]: e.target.value,
                        });
                        if (
                          item.paramName === 'account_number' &&
                          e.target.value > 9
                        ) {
                          handleValidate({
                            name: item.paramName,
                            value: e.target.value,
                          });
                        }
                      }}
                    />
                  )}
                </div>
              ))
            : ''}
          {isValidate && (
            <div className="inputbox-with-one-input">
              <input
                placeholder="Customer Name"
                type="text"
                disabled
                value={customerName}
              />
            </div>
          )}
          {isValidate && (
            <>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="Amount"
                  type="number"
                  value={postData.amount}
                  onChange={(e) => {
                    setPostData({
                      ...postData,
                      amount: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="inputbox-with-one-input">
                <input
                  placeholder="Phone"
                  type="text"
                  value={postData.phone}
                  onChange={(e) => {
                    setPostData({
                      ...postData,
                      phone: e.target.value,
                    });
                  }}
                />
              </div>
            </>
          )} */}
          <div className="inputbox-with-one-input">
            {/* <input placeholder="Card to Debit" type="text" /> */}
            <select
              name="filter"
              className=""
              value={walletdata.walletAccountId}
              onChange={(e) => {
                const [accountNo, amount] = e.target.value.split(',');
                setWalletData({
                  ...walletdata,
                  walletAccountNo: accountNo,
                });
                setBalance(amount);
                setAccountAirtime(accountNo);
              }}
            >
              <option value="Select" selected>
                Select Account
              </option>
              {walletList.length
                ? walletList.map(
                    (item) =>
                      item.savingsProductName !== 'COMMISSION' && (
                        <option
                          value={`${item.accountNo},${item?.clr_bal_amt}`}
                          key={item.accountNo}
                        >
                          {/* {`${item?.acct_crncy_code}-${item?.accountNo}`} */}
                          {`${item?.description} - ${item?.accountNo} (${
                            item.acct_crncy_code
                          } ${moneyFomatter(
                            Number(item?.clr_bal_amt).toFixed(2)
                          )})`}
                        </option>
                      )
                  )
                : ''}
            </select>
            <span className="input-detail">
              Your Balance is N{balance || 0}.00
            </span>
          </div>
          <div>
            <span> Transaction Fees N{charges || 0}.00 </span>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        {/* <Button
          className="btn-primary mx-auto mb-3 mt-2"
          color="primary"
          loading={loading}
          disabled={loading || false}
          onClick={() => {
            if (!activeAggregator) {
              swal('Oops!', 'Select a company', 'error');
              return;
            }
            if (!utilityAmount) {
              swal('Oops!', 'Amount cannot be empty', 'error');
              return;
            }
            if (!meterNo) {
              swal('Oops!', 'Meter number cannot be empty', 'error');
              return;
            }
            if (!accountAirtime) {
              swal('Oops!', 'Select account to debit', 'error');
              return;
            }
            setShowPin(true);
          }}
          content="Enter"
        >
          Next
        </Button> */}
        <Button
          className="btn btn-primary mx-auto mb-3 mt-2"
          color="primary"
          loading={loading}
          disabled={loading || false}
          onClick={() => {
            if (!activeAggregator) {
              swal('Oops!', 'Select a company', 'error');
              return;
            }
            if (!utilityAmount) {
              swal('Oops!', 'Amount cannot be empty', 'error');
              return;
            }
            if (!meterNo) {
              swal('Oops!', 'Meter number cannot be empty', 'error');
              return;
            }
            if (!accountAirtime) {
              swal('Oops!', 'Select account to debit', 'error');
              return;
            }
            setShowPin(true);
          }}
        >
          Next
        </Button>
      </ModalFooter>
      {showPin ? (
        <PinDetails
          showModal={showPin}
          hideModal={setShowPin}
          handleNext={handleSubmit}
          id="success-wrapper"
          title="Utility Bills"
          otp={pin}
          setOtp={setPin}
          setLoading={setVerifyLoading}
          loading={verifyLoading}
        />
      ) : (
        ''
      )}
    </Modal>
  );
};

UtilityBills.defaultProps = {
  id: '',
};

UtilityBills.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default UtilityBills;
