import React from 'react';
import { CustomerServiceOutlined } from '@ant-design/icons';
import { Call } from '@material-ui/icons';
import envConfig from '../../../utils/envConfig';

function SupportButton() {
  return (
    <footer style={{ position: 'fixed', left: 0, bottom: 20, width: '100%' }}>
      <div>
        <div className="col-md-11  text-right">
          <div style={{ marginRight: '10px', marginLeft: '10px' }}>
            <span
              // type="button"
              className="text-right mr-2"
              style={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyItems: 'center',
                float: 'right',
                marginRight: '10px',
              }}
            >
              <Call />
              <span>+2347066750658</span>
            </span>
          </div>{' '}
          <a
            href={`${envConfig.websiteAppUrl}/support.html`}
            target="_blank"
            rel="noreferrer"
          >
            <button
              type="button"
              className="btn btn-primary text-right mr-2"
              style={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyItems: 'center',
                float: 'right',
              }}
            >
              <CustomerServiceOutlined />
              <span>Support </span>
            </button>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default SupportButton;
