import dotenv from 'dotenv';

dotenv.config();

const {
  IS_LIVE,
  REACT_APP_WEBSITE_APP,
  REACT_APP_SOCIAL_APP,
  REACT_APP_BASE_URL,
  REACT_APP_LOGIN_PAGE,
  REACT_APP_CORPORATE_APP,
  REACT_APP_PERSONAL_APP,
} = process.env;

const envConfig = {
  isLive: IS_LIVE === 'live' || false,
  websiteAppUrl: REACT_APP_WEBSITE_APP || 'https://staging.wayabank.ng',
  // websiteAppUrl: REACT_APP_WEBSITE_APP
  //   ? 'https://localhost:3000'
  //   : 'https://staging.wayabank.ng',
  socialAppUrl: REACT_APP_SOCIAL_APP || '',
  baseUrl: REACT_APP_BASE_URL || '',
  login: REACT_APP_LOGIN_PAGE || 'https://wayabank.ng/login',
  corporateAppUrl: REACT_APP_CORPORATE_APP || '',
  personalAppUrl: REACT_APP_PERSONAL_APP || '',
  grantType: 'client_credentials',
  clientId: 'mAKGnEcWBIo-A5Y-JCf1qQ8D4LrHx1UJPq11KIldES4',
  secretId: 'WayaLinks1@$',
  disputeURL: 'https://dispute.wayalinks.com',
};

export default envConfig;
